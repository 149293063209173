// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import ConfiguratorCart from '@/components/misc/configurator-cart/index.vue'
import ConfiguratorCartMixin from '@/components/misc/configurator-cart/configurator-cart-mixin'
import confirmationTypes from '@/components/modals/confirmation/confirmationTypes'
import getPageTitle from '@/utils/getPageTitle'
import Localization from '@/utils/localization'
import showConfirmationModal from '@/utils/showConfirmationModal'
import { Nullable } from '@/_typings/types/nullable'
import { api as fullscreen } from 'vue-fullscreen'

export default defineComponent({
  name: 'configurator',
  components: {
    ConfiguratorCart
  },
  mixins: [ ConfiguratorCartMixin ],
  getPageTitle,
  Localization,
  data() {
    return {
      configurationIds: null as Nullable<any>,
      configuratorUrl: null as Nullable<string>,
      hasUnsavedChanges: false,
      totalLineCount: null as Nullable<number>,
      totalPrice: null as Nullable<number>
    }
  },
  created() {
    this.configurationIds = { rootConfigurationId: this.$route.query.rootConfigurationId, configurationId: this.$route.query.configurationId }
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage, false)
  },
  beforeUnmount() {
    window.removeEventListener('message', this.receiveMessage)
  },
  computed: {
    ...mapGetters({
      runtimeSessionId: 'getRuntimeSessionId'
    })
  },
  methods: {
    ...mapMutations([
      'setRuntimeSessionId'
    ]),
    closeConfigurator() {
      const navigateBack = () => {
        if (this.$route.params.opportunityId && this.$route.params.quoteId)
          this.$router.push({ name: 'opportunity-quote', params: { opportunityId: this.$route.params.opportunityId, quoteId: this.$route.params.quoteId } })
        else if (this.$route.params.opportunityId)
          this.$router.push({ name: 'opportunity', params: { opportunityId: this.$route.params.opportunityId } })
        else if (this.$route.params.quoteId)
          this.$router.push({ name: 'quote', params: { quoteId: this.$route.params.quoteId } })
        else
          this.$router.go(-1)
      }
      if (this.hasUnsavedChanges) {
        showConfirmationModal(confirmationTypes.UNSAVED_CHANGES, () => {
          this.$el.querySelector('iframe').contentWindow.postMessage({ type: 'REFRESH-CART', reconfigureCart: true }, '*')
          navigateBack()
        })
      } else {
        navigateBack()
      }
    },
    constructConfiguratorUrl() {
      this.configuratorUrl = this.configurationIds.rootConfigurationId && this.configurationIds.configurationId ?
        window.KUBE_SETTINGS.CPQResellerConfiguratorURL
          + `/deals/${this.$route.query.dealId}/carts/${this.$route.query.cartId}/configure`
          + '?rootConfigurationId=' + this.configurationIds.rootConfigurationId + '&configurationId=' + this.configurationIds.configurationId
          + (this.runtimeSessionId(this.$route.query.cartId) ? '&sessionId=' + this.runtimeSessionId(this.$route.query.cartId) : '')
        : null  
    },
    configureConfiguration(e: any) {
      this.configurationIds = { rootConfigurationId: e.rootConfigurationId, configurationId: e.configurationId }
    },
    receiveMessage(e: any) {
      switch (e.data.type) {
        case 'CONFIGURATOR-READY':
          this.setRuntimeSessionId({ key: this.$route.query.cartId, value: e.data.sessionId })
          break
        case 'CONFIGURATOR-CONFIGURATION-SAVED':
          this.$router.go(-1)
          break
        case 'CHANGE-CONFIGURATION':
          this.totalPrice = e.data.configuration.summary?.subTotal || e.data.configuration.totalPrice
          this.totalLineCount = e.data.configuration.summary?.products.length || e.data.configuration.cartLineCount
          break
        case 'CONFIGURATOR-CONFIGURATION-UNSAVED-CHANGES':
          this.hasUnsavedChanges = Boolean(e.data.hasUnsavedChanges)
          break
        case 'CONFIGURATOR-TOGGLE-FULL-SCREEN':
          fullscreen.toggle(document.querySelector('#configurator-iframe'), { callback: (isFullscreen) => { this.$el.querySelector('iframe').contentWindow.postMessage({ type: 'SET-IS-FULL-SCREEN', isFullscreen: isFullscreen }, '*') } })
          break
        default:
          break
      }
    },
    refreshConfiguration() {
      this.$el.querySelector('iframe').contentWindow.postMessage({ type: 'REFRESH-CONFIGURATION', rootConfigurationId: this.configurationIds.rootConfigurationId, configurationId: this.configurationIds.configurationId }, '*')
    },
    saveConfiguration() {
      this.$el.querySelector('iframe').contentWindow.postMessage({ type: 'SAVE-CONFIGURATION' }, '*')
    }
  },
  watch: {
    configurationIds(newValue, oldValue) {
      if (oldValue === null || (newValue.rootConfigurationId !== oldValue.rootConfigurationId && newValue.configurationId !== oldValue.configurationId)) this.constructConfiguratorUrl()
    },
    isVisibleCartDrawer(newValue, oldValue) {
      if (!newValue && oldValue) this.refreshConfiguration()
    }
  }
})
